html, body {
    height: 100%;
    margin: 0;
}

/*
Example of filling remaining space
https://stackoverflow.com/questions/23389098/fill-remaining-vertical-space-only-css

html, body {
  height: 100%;
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
}

.first {
  height: 50px;
}

.second {
  flex-grow: 1;
}

<div class="wrapper">
  <div class="first" style="background:#b2efd8">First</div>
  <div class="second" style="background:#80c7cd">Second</div>
</div>
*/
